import React, { forwardRef, useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
  TextField,
  Box,
  Radio,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import EventParticipant from "../shiftBasedCalendar/EventParticipant";
import { add, format } from "date-fns";
import { useLazyQuery, useMutation, useQuery, gql } from "@apollo/client";
import {
  CREATE_RESCHEDULE_OPTION,
  EXECUTE_OPTION,
  RESCHEDULE_SWAP_OPTIONS,
  FORCE_OPTIONS_FOR_EMPLOYEE,
  SCHEDULER_INITIATE_OPTION,
  GET_OFFICE_SCHEDULE_STATUS,
} from "../../api/gqlQueries";
import { userVar, forceOptionsExecution } from "../../cache";
import StarRateIcon from "@mui/icons-material/StarRate";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";
import RescheduleOptionsKey from "../rescheduling/RescheduleOptionsKey";
import { updateScheduleFromOption } from "../calendar/UpdateEvents";
import { DateTimePaginator } from "../../helpers/DateTimePaginator";
let toastObj;

function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}
const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: 138,
    maxWidth: 225,
  },
  shiftSelect: {
    width: 273,
  },
  select: {
    width: 300,
  },
  error: {
    color: theme.palette.primary.main,
  },
  dateError: {
    color: theme.palette.primary.main,
    width: 225,
    paddingLeft: 14,
    paddingRight: 14,
  },
  icon: {
    marginRight: -5,
    marginBottom: -6,
  },
  subtitle: {
    fontSize: 12,
    color: theme.palette.secondary.main,
  },
  box: {
    margin: 0,
    padding: 0,
  },
}));

const MangShiftSwitchRequestForm = forwardRef((props, ref) => {
  const classes = useStyles();

  const {
    closeDialog,
    date,
    scheduleEndDate,
    shiftSwitchBuffer,
    notifyDevelopers,
    environment,
    selectedOffice,
    selectedShift,
  } = props;

  const currentUser = userVar();
  const today = new Date();
  const minimumDate = add(new Date(), { days: shiftSwitchBuffer });

  const getSelectedDateShifts = (d) => {
    let uniqueShifts = [];
    let eventsForSelectedDate = ref.current
      .getEvents(new Date(d), add(new Date(d), { days: 1 }))
      .filter((e) => e.eventType === "SHIFTASSIGNMENT");
    eventsForSelectedDate &&
      eventsForSelectedDate.length > 0 &&
      eventsForSelectedDate.map((e) => {
        if (!uniqueShifts.find((x) => x.shiftId === e.shiftId)) {
          uniqueShifts.push(e);
        }
      });
    return uniqueShifts;
  };

  const [algorithmOnly, setAlgorithmOnly] = useState(true);
  const [error, setError] = useState("");
  const [desiredDateError, setDesiredDateError] = useState("");
  const [shift, setShift] = useState("Select Shift");
  const [shiftNames, SetShiftNames] = useState(
    getSelectedDateShifts(minimumDate),
  );
  const currentDate = new Date(selectedShift.start);
  const [desiredDate, setDesiredDate] = useState();
  const currentDateShiftId = selectedShift.shiftId;
  const [desiredDateShiftId, setDesiredDateShiftId] = useState("");
  const currentDateSelectedEmployee = selectedShift.employee.id;
  const [desiredDateSelectedEmployee, setDesiredDateSelectedEmployee] =
    useState("");
  const [forceOptionsFlag, setForceOptionsFlag] = useState(false);
  const [desiredDateEmployees, setDesiredDateEmployees] = useState([]);
  const [eligibleEmployees, setEligibleEmployees] = useState([]);

  const [rescheduleSwapAddOptions, setRescheduleSwapAddOptions] = useState({});

  const officeScheduleStatus = useQuery(GET_OFFICE_SCHEDULE_STATUS, {
    variables: {
      id: parseInt(selectedOffice.id),
    },
    onError(err) {
      console.error(err.message);
    },
  });

  const [rescheduleSwapOptns, { loading: rescheduleSwapOptionsLoading }] =
    useLazyQuery(RESCHEDULE_SWAP_OPTIONS, {
      fetchPolicy: "cache-and-network",
      onCompleted(data) {
        if (data.rescheduleSwaps.length > 0) {
          const swapsWithOptionStatus = data.rescheduleSwaps.filter((swap) => {
            const past =
              swap.rescheduleindividualSet[0].rescheduleactionSet.find(
                (action) => new Date(action.shift.start) < today,
              );

            return swap.status === "OPTION" && !past;
          });

          const options = swapsWithOptionStatus
            .map((option) => {
              const employeeNotCurrentSelected =
                option.rescheduleindividualSet.find(
                  (individual) =>
                    parseInt(individual.employee.id) !==
                      parseInt(currentDateSelectedEmployee) ||
                    option.rescheduleindividualSet.length === 1,
                );

              return {
                ...employeeNotCurrentSelected,
                optionId: option.id,
              };
            })
            .filter((option) => option.rescheduleactionSet);

          if (forceOptionsFlag === true) {
            toastShow(
              "Our Algorithm has generated some swap options for " +
                selectedShift.employee.firstName +
                " " +
                selectedShift.employee.lastName +
                " on " +
                format(selectedShift.start, " dd-MMM-yyyy."),
              "Success",
            );
          }

          setRescheduleSwapAddOptions(options);
        } else {
          if (forceOptionsFlag === true) {
            toastShow(
              "Our Algorithm could not generate options for you. Please try manual shift swap.",
            );
          }
          setRescheduleSwapAddOptions([]);
        }

        officeScheduleStatus.stopPolling();
        setForceOptionsFlag(false);
        forceOptionsExecution({ started: false });
      },
      onError(error) {
        toastShow(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
          "Error",
        );

        notifyDevelopers({
          variables: {
            message:
              "Error on RESCHEDULE_SWAP_OPTIONS lazyQuery. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    });

  useEffect(() => {
    rescheduleSwapOptns({
      variables: {
        shiftAssignments: parseInt(selectedShift.employee.shiftAssignmentId),
      },
    });
  }, []);

  // checks status for office schedule and and then shows forced shift options
  useEffect(() => {
    if (officeScheduleStatus.data) {
      if (officeScheduleStatus.data.offices[0].schedulingStatus === "READING") {
        setForceOptionsFlag(true);
      }
      if (
        officeScheduleStatus.data.offices[0].schedulingStatus === "COMPLETE" &&
        forceOptionsFlag === true
      ) {
        rescheduleSwapOptns({
          variables: {
            shiftAssignments: parseInt(
              selectedShift.employee.shiftAssignmentId,
            ),
          },
        });
      }
    }
  }, [officeScheduleStatus.data]);

  const [executeOption] = useMutation(EXECUTE_OPTION, {
    update(cache, { data: { executeOption } }) {
      const paginator = new DateTimePaginator(1);
      executeOption.slacks.forEach((element) => {
        const start = new Date(element.interval.start);
        const end = new Date(element.interval.end);
        const formatted = format(start, "MM/dd/yyyy");
        const dates = paginator.getQueryDateRanges(start, end);
        dates.forEach((date) => {
          const variables = {
            issuesOnly: true,
            office: selectedOffice.id,
            pageEnd: date[1].toISOString(),
            pageStart: date[0].toISOString(),
          };
          const strVariables = JSON.stringify(variables);
          cache.modify({
            fields: {
              intervalSlacks: (existing, { storeFieldName }) => {
                /**
                 * Modifier function to update the cached query `intervalSlacks`.
                 *
                 * Queries may be in the cache multiple times if they were called with
                 * different arguments. This callback function will be called on each of
                 * those cached fields so we check whether the variables we are interested
                 * in is contained in the `storeFieldName` which is the full key that includes
                 * the serialized variables. N.B. checking if a string includes a substring
                 * means that the order of the variables and the type (int vs string) matter.
                 * https://www.apollographql.com/docs/react/caching/cache-interaction#examples
                 * https://www.apollographql.com/docs/react/api/cache/InMemoryCache#modify
                 *
                 * @param {SlackNode[]} existing the array of object currently in the cache.
                 * @param {String} storeFieldName the serialized full key of the field including variable arguments
                 *
                 * @returns {SlackNode[]} the new object appended to existing or the existing array.
                 */
                if (storeFieldName.includes(strVariables)) {
                  const newSlackRef = cache.writeFragment({
                    data: element,
                    fragment: gql`
                      fragment NewSlack on SlackNode {
                        id
                        required
                        slack
                        numAssigned
                        interval {
                          id
                          start
                          end
                          shifts {
                            id
                            start
                            end
                            procedure {
                              id
                              procedureRequirement {
                                id
                                name
                              }
                            }
                            office {
                              id
                              name
                            }
                          }
                        }
                        skill {
                          id
                          name
                        }
                      }
                    `,
                  });
                  if (!ref.current.slackEvents[formatted]) {
                    ref.current.slackEvents[formatted] = {};
                  }
                  ref.current.slackEvents[formatted][element.id] = element;
                  return [...existing, newSlackRef];
                } else {
                  return existing;
                }
              },
            },
          });
        });
      });
    },
    onCompleted(data) {
      updateScheduleFromOption(
        ref.current,
        data.executeOption.shifts,
        selectedOffice.name,
      );
      toastShow("Manager Shift Switch Successfully Executed", "Success");
      closeDialog();
    },
    onError(error) {
      toastShow(
        "Unable to create shift switch request. Please check details and try again.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on EXECUTE_OPTION Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [schedulerInitiateOption] = useMutation(SCHEDULER_INITIATE_OPTION, {
    onCompleted(data) {
      toastShow("Manager Shift Switch Successfully Executed", "Success");
      closeDialog();
    },
    onError(error) {
      console.error(error);
      toastShow(
        "Unable to create shift switch request. Please check details and try again.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on SCHEDULER_EXECUTE_OPTION Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [createSwapOption] = useMutation(CREATE_RESCHEDULE_OPTION, {
    onCompleted(data) {
      if (
        window.MANAGER_APPROVES_EMPLOYEE_REQUESTS === true ||
        window.MANAGER_APPROVES_EMPLOYEE_REQUESTS === "true"
      ) {
        schedulerInitiateOption({
          variables: {
            option: parseInt(data.createRescheduleOption.option.id),
          },
        });
      }
      toastShow("Manager Shift Switch successfully executed", "Success");
      closeDialog();
    },
    onError(error) {
      toastShow(
        "Unable to create shift switch request. Please check details and try again.",
        "Error",
      );

      notifyDevelopers({
        variables: {
          message:
            "Error on CREATE_RESCHEDULE_OPTION Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [forceOptions] = useMutation(FORCE_OPTIONS_FOR_EMPLOYEE, {
    onCompleted(data) {
      if (data.forceOptions.successful) {
        officeScheduleStatus.startPolling(5000);
        toastShow(
          "Our Algorithm is generating some options and we will have them for you momentarily.",
          "Success",
        );
      }
    },
    onError(error) {
      console.error(error);
      toastShow(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on FORCE_OPTIONS_FOR_EMPLOYEE Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const handleDesiredDateChange = (date) => {
    if (date && !isNaN(date.getTime())) {
      let newDateShifts = getSelectedDateShifts(date);
      SetShiftNames(newDateShifts);
      setDesiredDate(date);
    } else {
      setDesiredDateError("Invalid date format");
    }
  };

  const handleShiftChange = (event) => {
    const newShift = event.target.value;
    setShift(newShift);
    const newDesiredEmployees = newShift.participants;

    if (newDesiredEmployees) {
      setDesiredDateEmployees(newDesiredEmployees);
      setEligibleEmployees(newDesiredEmployees);
    } else {
      setError("Invalid date or shift selection");
    }
  };

  //method that renders manual employee options
  const renderEmployeeOptions = (employees) => {
    if (employees && employees.length > 0) {
      return employees.map((employee, index) => (
        <MenuItem
          key={`${employee.lastName}-${index}`}
          value={employee.employeeId}
        >
          <EventParticipant
            participant={employee}
            showAvatar={false}
            widthMatters={false}
            showJobTitle={true}
          />
        </MenuItem>
      ));
    } else {
      return (
        <MenuItem value={"None"}>
          <Typography>No Recommended Employees</Typography>
        </MenuItem>
      );
    }
  };

  const formatOptions = (name, isUser) => {
    let displayName = "No backfill";
    if (!isUser) {
      displayName = ` ${name}`;
    }
    return (
      <>
        <Typography
          variant="caption"
          style={{
            fontWeight: 500,
          }}
        >
          {displayName}
        </Typography>
      </>
    );
  };

  const handleRadioChange = (radioId) => {
    if (radioId === "None") {
      setDesiredDateSelectedEmployee("None");
      return;
    } else {
      if (algorithmOnly) {
        //path for desired date selected option change when
        //algorithm only is checked
        const optionId = radioId;
        const matchingOption = rescheduleSwapAddOptions.find(
          (option) => option.optionId === optionId,
        );

        const dropAction = matchingOption
          ? matchingOption.rescheduleactionSet.find(
              (action) => action.actionType === "DROP",
            )
          : null;
        setDesiredDateSelectedEmployee(optionId);

        if (dropAction) {
          setDesiredDateShiftId(dropAction.shift.id);
          setDesiredDate(new Date(dropAction.shift.start));
          setError("");
        } else {
          setError(
            "We could not find that employee's assignment. " +
              "Please try your selections again.",
          );
        }
      } else {
        //path for desired date selected employee change when
        //algorithm only is NOT checked
        const employeeId = optionId;

        if (shift) {
          setDesiredDateSelectedEmployee(employeeId);
          setDesiredDateShiftId(shift.shiftId);
          setError("");
        } else {
          setError(
            "We could not find that employee's assignment. " +
              "Please try your selections again.",
          );
        }
      }
    }
  };

  //method that renders reschedule options with date
  const renderEmployeeOptionswithDate = (options) => {
    const duplicateShiftMap = {};
    if (options.length > 0) {
      const sortedOptions = [...options];
      sortedOptions.sort((a, b) => b.benefit - a.benefit);

      return sortedOptions.map((option, index) => {
        let shiftTitle;
        let isUser = option.employee.id === currentDateSelectedEmployee;
        let fullName = `${option.employee.firstName}
          ${option.employee.lastName}`;

        if (option.rescheduleactionSet.length > 0) {
          let shift;
          let shiftId;
          if (isUser) {
            shift = option.rescheduleactionSet.find(
              (e) => e.actionType === "ADD",
            );
            shiftId = `${option.employee.id}-${shift.shift.id}`;
          } else {
            shift = option.rescheduleactionSet.find(
              (e) => e.actionType === "DROP",
            );
            shiftId = `${option.employee.id}-${shift.shift.id}`;
          }

          if (duplicateShiftMap[shiftId]) {
            return null;
          } else {
            duplicateShiftMap[shiftId] = shift;
          }
          shiftTitle = shift
            ? format(new Date(shift.shift.start), " dd MMM ") +
              format(new Date(shift.shift.start), "HH:mm") +
              "-" +
              format(new Date(shift.shift.end), "HH:mm")
            : "";
        }

        return (
          <Grid
            item
            xs={6}
            container
            alignItems="center"
            spacing={1}
            key={index}
          >
            <Grid item xs="auto">
              <Radio
                checked={desiredDateSelectedEmployee === option.optionId}
                onChange={() => handleRadioChange(option.optionId)}
              />
            </Grid>
            <Grid item xs="auto">
              <Box className={classes.box}>
                <Typography display="inline">
                  {option.benefit <= -1 && (
                    <StarRateIcon className={classes.icon} />
                  )}
                  {option.benefit < 1 && option.benefit > -1 && (
                    <>
                      <StarRateIcon className={classes.icon} />
                      <StarRateIcon className={classes.icon} />
                    </>
                  )}
                  {option.benefit >= 1 && (
                    <>
                      <StarRateIcon className={classes.icon} />
                      <StarRateIcon className={classes.icon} />
                      <StarRateIcon className={classes.icon} />
                    </>
                  )}

                  <Typography variant="subtitle1" display="inline" noWrap>
                    {`  ${shiftTitle}`}
                  </Typography>
                </Typography>
                {option.rescheduleactionSet.length > 0 && (
                  <Typography noWrap>
                    {formatOptions(fullName, isUser)}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        );
      });
    } else {
      return <MenuItem value={"None"}>No Eligible Employees</MenuItem>;
    }
  };

  const validRequest = Boolean(
    algorithmOnly
      ? desiredDateSelectedEmployee && currentDate > today
      : desiredDateShiftId &&
          desiredDateSelectedEmployee &&
          currentDate > today &&
          desiredDate > today,
  );

  const handleSubmit = () => {
    if (algorithmOnly) {
      if (
        window.MANAGER_APPROVES_EMPLOYEE_REQUESTS === true ||
        window.MANAGER_APPROVES_EMPLOYEE_REQUESTS === "true"
      ) {
        schedulerInitiateOption({
          variables: {
            option: parseInt(desiredDateSelectedEmployee),
          },
        });
      } else {
        executeOption({
          variables: {
            option: parseInt(desiredDateSelectedEmployee),
            approver: parseInt(currentUser.id),
          },
        });
      }
    } else {
      const individuals = [
        {
          employee: parseInt(currentDateSelectedEmployee),
          cost: 0,
          benefit: 0,
          actions: [
            {
              actionType: "ADD",
              shift: desiredDateShiftId,
            },
            {
              actionType: "DROP",
              shift: currentDateShiftId,
            },
          ],
        },
        {
          employee: parseInt(desiredDateSelectedEmployee),
          cost: 0,
          benefit: 0,
          actions: [
            {
              actionType: "DROP",
              shift: desiredDateShiftId,
            },
            {
              actionType: "ADD",
              shift: currentDateShiftId,
            },
          ],
        },
      ];
      if (
        window.MANAGER_APPROVES_EMPLOYEE_REQUESTS === true ||
        window.MANAGER_APPROVES_EMPLOYEE_REQUESTS === "true"
      ) {
        createSwapOption({
          variables: {
            optionType: "SWAP",
            individuals: individuals,
            userGenerated: true,
          },
        });
      } else {
        createSwapOption({
          variables: {
            optionType: "SWAP",
            status: "APPROVED",
            individuals: individuals,
            userGenerated: true,
          },
        });
      }
    }
  };
  const userJobTypes = new Set(
    selectedShift.employee.skillSet.map((skill) => skill.name),
  );

  const handleAlgorithmOnlyChange = (event) => {
    setError("");
    setDesiredDateSelectedEmployee("");
    setAlgorithmOnly(event.target.checked);
  };

  if (rescheduleSwapOptionsLoading) {
    return <CircularProgress />;
  } else {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {/* <Grid item>
          <FormControlLabel
            data-testid="systemGeneratedSwitchMangShiftSwitch"
            control={
              <Switch
                checked={algorithmOnly}
                onChange={handleAlgorithmOnlyChange}
                name="algorithmOnly"
                color={algorithmOnly ? "primary" : "secondary"}
              />
            }
            label="System-Generated Options"
          ></FormControlLabel>
        </Grid> */}
        <Grid item>
          <Typography variant="h4">Current Shift Information</Typography>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={6} style={{ marginTop: 16 }}>
            <Typography variant="h6">Employee Name</Typography>
            <Typography variant="body1">
              {selectedShift.employee.firstName +
                " " +
                selectedShift.employee.lastName}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ marginTop: 16 }}>
            <Typography variant="h6">Shift time and date</Typography>
            <Typography variant="body1">
              {format(selectedShift.start, "MMMM dd yyyy hh:mm a") +
                " to " +
                format(selectedShift.end, "MMMM dd yyyy hh:mm a")}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ marginTop: 16 }}>
            <Typography variant="h6">Location</Typography>
            <Typography variant="body1">{selectedShift.officeName}</Typography>
          </Grid>
          <Grid item xs={6} style={{ marginTop: 16 }}>
            <Typography variant="h6">Skill</Typography>
            <Typography variant="body1">
              {userJobTypes.map((e) => e).join(", ")}
            </Typography>
          </Grid>
        </Grid>
        {algorithmOnly === false && (
          <>
            <Grid
              item
              container
              justifyContent="flex-start"
              spacing={2}
              style={{ marginTop: 16 }}
            >
              <Grid item>
                <InputLabel htmlFor="desired-date">
                  <Typography variant="h6">Desired Date</Typography>
                </InputLabel>
                <DatePicker
                  disableToolbar
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  id="desired-date"
                  value={desiredDate}
                  onChange={handleDesiredDateChange}
                  minDate={minimumDate}
                  className={classes.input}
                  disabled={algorithmOnly}
                  renderInput={(props) => <TextField {...props} />}
                  data-testid="desiredDateMangShiftSwitch"
                />
                {desiredDateError && (
                  <Typography
                    variant="body2"
                    className={classes.dateError}
                    data-testid="desiredDateErrMangShiftSwitch"
                  >
                    {desiredDateError}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item style={{ marginTop: 16 }}>
              <InputLabel id="shift-select-label" shrink={false}>
                <Typography variant="h6">Shift</Typography>
              </InputLabel>
              <Select
                labelId="shift-select-label"
                id="shift-select"
                variant="outlined"
                value={shift}
                onChange={handleShiftChange}
                data-testid="selectShiftSwitchMangShiftSwitch"
              >
                <MenuItem key="Select Shift" value="Select Shift">
                  Select Shift
                </MenuItem>
                {shiftNames.map((shiftName) => (
                  <MenuItem key={shiftName.shiftId} value={shiftName}>
                    {format(new Date(shiftName.start), "MMMM dd yy hh:mm a") +
                      " to " +
                      format(new Date(shiftName.end), "MMMM dd yy hh:mm a")}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </>
        )}
        {algorithmOnly === true && (
          <Grid
            item
            container
            justifyContent="flex-start"
            style={{ marginTop: 15 }}
          >
            <RescheduleOptionsKey type="switch" />
          </Grid>
        )}

        <Grid container style={{ marginTop: 16 }}>
          <Typography variant="h6">Suggested Employees</Typography>
        </Grid>
        <Grid
          container
          style={{ marginTop: 16, maxHeight: "200px", overflowY: "auto" }}
        >
          {algorithmOnly
            ? renderEmployeeOptionswithDate(rescheduleSwapAddOptions)
            : renderEmployeeOptions(eligibleEmployees)}
        </Grid>
        <Grid
          item
          container
          justifyContent="flex-end"
          style={{ marginTop: 16 }}
        >
          <Grid item>
            {error && (
              <Typography className={classes.error}>{error}</Typography>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent="flex-end"
          style={{
            marginTop: 20,
            zIndex: 4,
          }}
          spacing={2}
        >
          {currentDateShiftId != "" && rescheduleSwapAddOptions.length <= 0 && (
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                style={{ width: 169, height: 39 }}
                onClick={() => {
                  forceOptions({
                    variables: {
                      employee: parseInt(currentDateSelectedEmployee),
                      shifts: [currentDateShiftId],
                    },
                  });
                  forceOptionsExecution({
                    started: true,
                  });
                }}
                data-testid="forceOptionsMangShiftSwitch"
                disabled={
                  forceOptionsExecution().started === true ? true : false
                }
              >
                {forceOptionsExecution().started === true && (
                  <CircularProgress />
                )}
                Force Options
              </Button>
            </Grid>
          )}

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!validRequest}
              style={{ width: 169, height: 39 }}
              data-testid="shiftSwitchMangShiftSwitch"
            >
              Switch Shifts
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>
    );
  }
});

export default MangShiftSwitchRequestForm;
