import { useState } from "react";
import {
  ScheduleComponent,
  Month,
  Inject,
  ViewsDirective,
  ViewDirective,
} from "@syncfusion/ej2-react-schedule";
import { formatDate } from "../../helpers/FormatDate";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import { ArrangeSendBackward } from "mdi-material-ui";

const exampleBackendData: Record<string, { availability: boolean }> = {
  "11/16/24": { availability: false },
  "11/17/24": { availability: true },
};

interface AvailabilityProps {
  toggleAvailabilityForm: () => void;
}
const Availability = (props: AvailabilityProps) => {
  const { toggleAvailabilityForm } = props;
  const [availability, setAvailability] = useState(exampleBackendData);

  const toggleAvailability = (date: string) => {
    setAvailability((prevData) => {
      let updatedAvailability = !prevData[date]?.availability;
      return {
        ...prevData,
        [date]: {
          availability: updatedAvailability,
        },
      };
    });
  };

  const CellTemplate = ({ date }: any) => {
    const env = { hourCycle: "h23" };
    const cellDate = date;
    const formattedDate = formatDate(cellDate, env);

    const isAvailable: boolean =
      availability[formattedDate.toString()]?.availability;

    return (
      <Grid container justifyContent="center">
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <Typography
              variant="subtitle2"
              style={{ color: isAvailable ? "#3C3C3C" : "#C9C9C9" }}
              data-testid={`cellText-${formattedDate}`}
            >
              <b>{isAvailable ? "Available" : "Not Available"}</b>
            </Typography>
          </FormLabel>
          <FormGroup
            onClick={() => toggleAvailability(formattedDate.toString())}
          >
            <FormControlLabel
              control={<Switch checked={isAvailable} />}
              label=""
              data-testid={`toggle-${formattedDate}`}
            />
          </FormGroup>
        </FormControl>
      </Grid>
    );
  };

  const onCellRender = (args: any) => {
    console.log("ARGS", args);
    //Only target the cells, not the workday cells
    if (args.elementType === "monthCells") {
      const cellDate = args.date;
      const formattedDate = formatDate(cellDate, {
        hourCycle: "h23",
      }).toString();

      if (availability[formattedDate]?.availability) {
        args.element.style.backgroundColor = "#8BAF7D";
      } else {
        args.element.style.backgroundColor = "#696565";
      }
    }
  };

  const handleSubmit = () => {
    const availableDates = Object.fromEntries(
      Object.entries(availability).filter(
        ([date, data]) => data.availability === true,
      ),
    );

    alert(`Availability ${JSON.stringify(availableDates)}`);
  };

  return (
    <Grid container>
      <Grid item xs={1}>
        <IconButton
          aria-label="close"
          color="secondary"
          size="small"
          onClick={toggleAvailabilityForm}
          data-testid="close-availability-form"
        >
          <CloseIcon />
        </IconButton>
      </Grid>

      <Grid item xs={10}></Grid>

      <Grid item xs={1}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleSubmit}
          data-testid="submit-availability"
        >
          SUBMIT
        </Button>
      </Grid>

      <Grid item xs={12}>
        <ScheduleComponent
          data-testid="availability-schedule"
          showQuickInfo={false}
          cellTemplate={CellTemplate}
          renderCell={onCellRender}
          toolbarItems={[
            { name: "Today", visible: false },
            { name: "Previous", visible: true },
            { name: "Next", visible: true },
            { name: "DateRangeText", visible: true },
          ]}
        >
          <ViewsDirective>
            <ViewDirective option="Month" />
          </ViewsDirective>
          <Inject services={[Month]} />
        </ScheduleComponent>
      </Grid>
    </Grid>
  );
};

export default Availability;
