import React, { useState } from "react";
import { Grid, Dialog, DialogContent, Button } from "@mui/material";
import Availability from "./Availability";

const AddAvailabilityForm = () => {
  const [availabilityForm, setAvailabilityForm] = useState(false);
  const toggleAvailabilityForm = () => setAvailabilityForm(!availabilityForm);

  return (
    <>
      <Grid item>
        <Button
          variant="outlined"
          color={"primary"}
          onClick={toggleAvailabilityForm}
          data-testid="addCalendarEvent"
        >
          Add Availability
        </Button>
      </Grid>
      <Dialog open={availabilityForm} fullWidth maxWidth="lg">
        <DialogContent data-testid="ShiftBasedtimeOffRequestForm">
          <Availability toggleAvailabilityForm={toggleAvailabilityForm} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddAvailabilityForm;
